<template>
  <validation-provider
    :rules="validate"
    v-slot="{ errors }"
    :name="name"
    :immediate="immediate"
    :vid="vid"
  >
    <div
      class="form-group"
      :class="{ 'has-error': errors.length, valid: !errors.length }"
      :style="{ marginBottom: `${marginBottom}px` }"
    >
      <div class="input-group" :class="{'has-margin-top': label || margin}">
        <textarea
          :type="realType"
          :required="required"
          :disabled="disabled"
          :name="name"
          :id="name"
          :maxlength="maxLength"
          v-model="iValue"
          :placeholder="placeholder"
          :rows="rows"
          @focus="handleFocus()"
          @blur="handleBlur()"
          :autofocus="focused"
          >{{ value }}</textarea
        >

        <label class="control-label" :for="name">
          {{ label }}
          <a
            v-if="tooltip"
            v-b-tooltip.hover
            :title="tooltip"
            class="fa fa-question-circle tooltip-icon"
          />
        </label>
        <i v-if="!hideBar" class="bar"></i>
        <div class="d-flex justify-content-between">
          <small v-show="errors.length" class="help text-danger">{{
            errors[0]
          }}</small>
          <div v-if="maxLength && !hideLength" class="text-right ml-auto">
            <small
              class="help"
              :class="{ 'text-danger': valueLength > maxLength }"
            >
              <template v-if="maxLength >= valueLength"
                >{{ maxLength - valueLength }} characters remaining</template
              >
              <template v-else
                >{{ valueLength - maxLength }} characters over limit
                {{ maxLength }}</template
              >
            </small>
          </div>
        </div>
        <small class="help" v-if="help">{{ help }}</small>
      </div>
    </div>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    name: {
      required: false,
    },
    margin: {
      default: false,
      required: false,
    },
    label: {
      required: false,
    },
    required: {
      default: false,
      required: false,
    },

    help: {
      required: false,
    },

    tooltip: {
      required: false,
    },

    disabled: {
      required: false,
      default: false,
    },
    validate: {
      required: false,
      default: null,
    },
    maxLength: {
      required: false,
      default: 160,
    },

    type: {
      type: String,
      required: false,
      default: "text",
    },

    placeholder: {
      type: String,
    },
    immediate: {
      required: false,
      default: false,
    },
    vid: {
      type: String,
    },
    rows: {
      type: Number,
      default: 2,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    hideLength: {
      required: false,
      default: false,
    },
    marginBottom: {
      type: Number,
      default: undefined,
    },
    hideBar: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  data() {
    return {
      masks: {
        text: "",
      },
    };
  },
  computed: {
    realType() {
      return this.type;
    },
    mask() {
      return this.masks[this.type];
    },

    valueLength() {
      if (this.value) return this.value.length;
      return 0;
    },

    iValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    handleFocus() {
      if (["intro", "buttonLabel", "callScheduleLabel"].includes(this.name)) {
        this.$emit("focus", "call");
      } else if (["callLabel"].includes(this.name)) {
        this.$emit("focus", "call-schedule");
      } else if (["voiceRecordButtonLabel", "voiceRecordText"].includes(this.name)) {
        this.$emit("focus", "voice");
      } else if (["gate", "headerTitle", "callConnectText", "smsConnectText", "recordConnectText"].includes(this.name)) {
        this.$emit("focus", "gate");
      } else if (this.name == "department") {
        this.$emit("focus", "department");
      } else if (["offline", "scheduleButtonLabel"].includes(this.name)) {
        this.$emit("focus", "schedule");
      } else if (this.name == "widgetTooltip") {
        this.$emit("focus", "tooltip");
      } else if (this.name == "widgetMobileSmsDefaultText") {
        this.$emit("focus", "mobile-button");
      } else if (["smsResponseTitleText", "smsResponseText"].includes(this.name)) {
        this.$emit("focus", "smsResponse");
      } else if (["voiceResponseTitleText", "voiceResponseText"].includes(this.name)) {
        this.$emit("focus", "voiceResponse");
      } else if (["scheduledVoiceResponseText"].includes(this.name)) {
        this.$emit("focus", "scheduledVoiceResponse");
      } else if (["voiceRecordResponseTitleText", "voiceRecordResponseText"].includes(this.name)) {
        this.$emit("focus", "voiceRecordResponse");
      } else {
        this.$emit("focus", "sms");
      }
    },
    handleBlur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped lang="scss">
.form-group textarea:disabled ~ .control-label {
  color: #333;
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  top: -0.6rem;
  left: 0;
}
.form-group textarea:disabled {
  cursor: not-allowed;
}
.form-group .control-label {
  pointer-events: unset;
  overflow: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.tooltip-icon {
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
  margin-top: calc(4px - 8px);
}
</style>
